import React from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useWindowBreakpoints } from 'design-system/hooks';
import {
    Divider,
    HStack,
    Stack,
    StackProps,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { LayoutBoundaryType, TermsModal as TermsModalCore } from 'core';
import { LayoutBoundary } from './Layouts';
import { pageTitles } from 'lib/site';
import { usePrivacyPolicy, useTerms } from 'lib/user';

type TermsModalProps = {
    onClose: () => void;
    selectedTerms: 'cgu' | 'privacyPolicy';
    microService?: string;
    layoutProps?: Partial<LayoutBoundaryType>;
};

export type TermsFooterProps = Pick<
    TermsModalProps,
    'microService' | 'layoutProps'
> & {
    textColor?: string;
    stackProps?: StackProps;
    displayCgu?: boolean;
    displayPrivacyPolicy?: boolean;
};

export default function TermsFooter({
    textColor,
    stackProps,
    displayCgu,
    displayPrivacyPolicy,
    microService,
    layoutProps,
}: TermsFooterProps) {
    const { isMobile } = useWindowBreakpoints();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedTerms, setSelectedTerms] = React.useState<
        'cgu' | 'privacyPolicy'
    >();

    React.useEffect(() => {
        setTimeout(() => {
            const modalContentContainer = document.querySelector(
                '.chakra-modal__content-container'
            );
            modalContentContainer?.scrollTo(0, 0);
        }, 100);
    }, [isOpen]);

    const StackComponent = isMobile ? Stack : HStack;

    return (
        <>
            <StackComponent
                w="full"
                alignItems="center"
                justifyContent="center"
                spacing={isMobile ? 2 : 4}
                px={isMobile && 5}
                mb={isMobile && 2}
                divider={
                    isMobile ? (
                        <Divider bg="strokes.medium" />
                    ) : (
                        <Divider h="4" w="0" />
                    )
                }
                {...stackProps}>
                {displayCgu && (
                    <ModalOpenerLink
                        {...{
                            color: textColor,
                            text: pageTitles.termsOfUse,
                            onOpen: () => {
                                onOpen();
                                setSelectedTerms('cgu');
                            },
                        }}
                    />
                )}
                {displayPrivacyPolicy && (
                    <ModalOpenerLink
                        {...{
                            color: textColor,
                            text: pageTitles.privacyPolicy,
                            onOpen: () => {
                                onOpen();
                                setSelectedTerms('privacyPolicy');
                            },
                        }}
                    />
                )}
            </StackComponent>

            {isOpen && (
                <TermsModal
                    onClose={onClose}
                    selectedTerms={selectedTerms}
                    microService={microService}
                    layoutProps={layoutProps}
                />
            )}
        </>
    );
}

type ModalOpenerLinkProps = {
    color: string;
    text: MessageDescriptor;
    onOpen: () => void;
};

function ModalOpenerLink({ color, text, onOpen }: ModalOpenerLinkProps) {
    return (
        <Text
            w="fit-content"
            color={color}
            textDecoration="underline"
            textAlign="center"
            cursor="pointer"
            fontSize="xs"
            fontWeight="bold"
            _hover={{
                color: color === 'white' ? 'grey.100' : 'primary.main',
                textDecorationColor:
                    color === 'white' ? 'grey.100' : 'primary.main',
            }}
            onClick={onOpen}>
            <FormattedMessage {...text} />
        </Text>
    );
}

function TermsModal({
    onClose,
    selectedTerms,
    microService,
    layoutProps,
}: TermsModalProps) {
    const { formatMessage } = useIntl();
    const { data: gcu, status: gcuStatus } = useTerms(false, microService);
    const { data: privacyPolicy, status: privacyPolicyStatus } =
        usePrivacyPolicy(false, microService);
    const statuses = { cgu: gcuStatus, privacyPolicy: privacyPolicyStatus };
    const data = { ...gcu, ...privacyPolicy };
    return (
        <TermsModalCore
            onClose={onClose}
            status={statuses[selectedTerms]}
            title={formatMessage(
                selectedTerms === 'cgu'
                    ? pageTitles.termsOfUse
                    : pageTitles.privacyPolicy
            )}
            content={data[selectedTerms]}
            isOpen
            LayoutBoundary={LayoutBoundary}
            layoutProps={layoutProps}
        />
    );
}
